import gsap from 'gsap';
const el = document.querySelector('#signup-popup');
const btn = el.querySelector('#signup-popup-close');
const link = el.querySelector('#signup-popup-link');
const cookieBanner = document.querySelector('#cookie-consent');

function initResizeListener() {
  // Setup a timer
  let timeout;
  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function () {
      // Run our resize functions
  		position();
  	});
  }, false);
}

function close() {
  gsap.set(el, {display: 'none'});
  localStorage.setItem('signup', 'closed');
}

function position() {
  const bannerHeight = cookieBanner.offsetHeight;
  gsap.set(el, {y: -bannerHeight})
}

export default function() {
  const status = localStorage.getItem('signup');
  if (!status) {
    gsap.set(el, {display: 'block'});
  }
  btn.addEventListener('click', close);
  // position();
  // initResizeListener();
}
