import gsap from 'gsap'
let tl
let lastWindowWidth = window.innerWidth

function resizeHandler() {
  tl.progress(1)
  const activeTeam = document.querySelector('.team-is-active')
  const activeInfo = activeTeam.querySelector('.js-team-info')
  const activePeople = document.querySelector('.people-is-active')
  const activeChildEls = Array.from(activePeople.children)
  const activeInfoHeight = `${activeInfo.scrollHeight}px`

  tl
  .set(activeInfo, {height: activeInfoHeight, autoAlpha: 1, overflow: 'auto'})
  .set(activeChildEls, {display: 'flex', autoAlpha: 1, y: 0})
  .from(activeInfo, {
    duration: 1,
    height: 0,
    autoAlpha: 0,
    ease: "power1.out"
  })
  .from(activeChildEls, {
    duration: 1,
    stagger: 0.2,
    autoAlpha: 0,
    y: 10
  }, "-=1")

  if (window.innerWidth < 960) {
    closeModal()
  }
}

function initResizeListener() {
  // Setup a timer
  let timeout
  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout)
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
      if (window.innerWidth !== lastWindowWidth) {
        resizeHandler()
        lastWindowWidth = window.innerWidth
      }
  	})
  }, false)
}

function selectTeam(e) {
  if (tl.isActive()) {
    tl.progress(1)
  }

  const clicked = e.currentTarget
  const teamId = clicked.dataset.team
  const newTeam = clicked.parentElement
  const newInfo = document.querySelector(`#team-info-${teamId}`)
  const activeTeam = document.querySelector('.team-is-active')
  const activeInfo = activeTeam.querySelector('.js-team-info')

  const activePeople = document.querySelector('.people-is-active')
  const activeChildEls = Array.from(activePeople.children)
  const newPeople = document.querySelector(`#team-people-${teamId}`)
  const newChildEls = Array.from(newPeople.children)

  if (activeTeam !== newTeam) {
    const newInfoHeight = `${newInfo.scrollHeight}px`

    tl
    .set(newInfo, {height: newInfoHeight, autoAlpha: 1, overflow: 'auto'})
    .set(newChildEls, {display: 'flex', autoAlpha: 1, y: 0})
    .to(activeInfo, {
      duration: 0.4,
      height: 0,
      autoAlpha: 0,
      ease: "none",
      overflow: 'hidden'
    })
    .to(activeChildEls, {
      duration: 0.2,
      autoAlpha: 0,
      ease: "none",
      display: "none"
    }, '-=0.4')
    .add(function() {
      activeTeam.classList.remove('team-is-active')
      newTeam.classList.add('team-is-active')

      activePeople.classList.remove('people-is-active')
      newPeople.classList.add('people-is-active')
    })
    .from(newInfo, {
      height: 0,
      autoAlpha: 0,
      duration: 1,
      ease: "power1.out"
    })
    .from(newChildEls, {
      duration: 1,
      stagger: 0.2,
      autoAlpha: 0,
      y: 10
    }, "-=1")
  }
}

function openModal(e) {
  const clicked = e.currentTarget
  const personId = clicked.dataset.person
  const modal = document.querySelector(`#person-modal-${personId}`)
  const rootEl = document.documentElement

  modal.classList.add('modal-is-active')
  rootEl.classList.add('modal-is-active')
}

function closeModal() {
  const items = Array.from(document.querySelectorAll('.modal-is-active'))
  items.forEach(item => item.classList.remove('modal-is-active'))
}

const team = {
  init() {
    const teamBtns = Array.from(document.querySelectorAll('.js-team-select'))
    const peopleBtns = Array.from(document.querySelectorAll('.js-person-select'))
    const closeModalBtns = Array.from(document.querySelectorAll('.js-close-modal'))

    if (teamBtns.length) {
      teamBtns.forEach(btn => {
        btn.addEventListener('click', selectTeam)
      })

      peopleBtns.forEach(btn => {
        btn.addEventListener('click', openModal)
      })

      closeModalBtns.forEach(btn => {
        btn.addEventListener('click', closeModal)
      })

      window.addEventListener('keyup', function(e) {
        const modal = document.querySelector('.modal-is-active')

        if (modal && e.key === 'Escape') {
          closeModal()
        }
      })

      window.addEventListener('load', function(e) {
        const activePeople = document.querySelector('.people-is-active')
        const activeChildEls = Array.from(activePeople.children)
        tl = gsap.timeline()

        tl
        .set(activeChildEls, {position: 'relative', autoAlpha: 0, y: 10})
        .fromTo(activeChildEls, {
          autoAlpha: 0,
          y: 10
        }, {
          duration: 1,
          stagger: 0.2,
          autoAlpha: 1,
          y: 0
        })
      });

      initResizeListener()
    }
  }
}

export default team
