import gsap from 'gsap'

const route = {
  init() {
    const links = document.querySelectorAll('a:not([target="_blank"]):not([id*="load-more"])');
    const body = document.body;
    const bodyContent = document.querySelector('#body-content');
    if (links.length) {
      links.forEach(link => {
        link.addEventListener('click', function(e) {
          e.preventDefault();
          const clicked = e.currentTarget;
          if (clicked.id === 'signup-popup-link') {
            localStorage.setItem('signup', 'closed');
          }
          const href = clicked.href;
          gsap.to(bodyContent, {
            duration: 0.1,
            opacity: 0,
            ease: 'none',
            onComplete: () => {
              window.location = href;
            }
          });
        });
      });
    }

    window.addEventListener('load', (event) => {
      body.classList.add('body-is-ready');
      gsap.to(bodyContent, {
        duration: 1,
        opacity: 1
      });
    });
  }
}

export default route;
