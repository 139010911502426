import gsap from 'gsap'
let lastWindowWidth = window.innerWidth

function showAnswer(faq) {
  const answer = faq.querySelector('.js-faq-answer')
  faq.classList.add('faq-is-open')
  gsap.set(answer, {
    height: 'auto'
  })
  gsap.from(answer, {
    duration: 0.5,
    height: 0,
    ease: ''
  })
}

function hideAnswer(faq) {
  const answer = faq.querySelector('.js-faq-answer')
  faq.classList.remove('faq-is-open')
  gsap.to(answer, {
    duration: 0.4,
    height: 0,
    ease: 'none'
  })
}

function toggleAnswer(e) {
  const clicked = e.currentTarget
  const faq = clicked.parentElement

  if (faq.classList.contains('faq-is-open')) {
    hideAnswer(faq)
  } else {
    showAnswer(faq)
  }
}

function initResizeListener() {
  // Setup a timer
  let timeout;

  // Listen for resize events
  window.addEventListener('resize', function ( event ) {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}

    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function () {
      if (window.innerWidth !== lastWindowWidth) {
        // Run our resize functions
        const openFaqs = Array.from(document.querySelectorAll('.faq-is-open'))
        if (openFaqs.length) {
          openFaqs.forEach(faq => showAnswer(faq))
        }
        lastWindowWidth = window.innerWidth
      }
  	});
  }, false);
}

const faqs = {
  init() {
    const faqBtns = Array.from(document.querySelectorAll('.js-faq-btn'))

    if (faqBtns.length) {
      faqBtns.forEach(btn => {
        btn.addEventListener('click', function(e) {
          toggleAnswer(e)
        })
      })

      initResizeListener()
    }
  }
}

export default faqs
