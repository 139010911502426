import gsap from 'gsap'
import latest from './latest'
const { ajaxLoad } = latest
let lastWindowWidth = window.innerWidth

function initResizeListener() {
  // Setup a timer
  let timeout
  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout)
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
      if (window.innerWidth !== lastWindowWidth) {
        resizeHandler()
        lastWindowWidth = window.innerWidth
      }
  	})
  }, false)
}

function resizeHandler() {
  const currentActive = document.querySelector('.js-filters-menu.filters-menu-is-active')
  const currentActiveBtn = document.querySelector('.js-filters-select-btn.filters-menu-is-active')

  if (currentActive) {
    showList({
      list: currentActive,
      btn: currentActiveBtn,
    })
  }
}

function menuBtnHandler(e) {
  const clicked = e.currentTarget
  const selector = clicked.dataset.toggle
  const matchingMenu = document.querySelector(`#menu-${selector}`)
  const currentActive = document.querySelector(`.js-filters-menu.filters-menu-is-active`)
  const currentActiveBtn = document.querySelector('.js-filters-select-btn.filters-menu-is-active')

  if (currentActive && currentActive !== matchingMenu) {
    hideList({
      list: currentActive,
      btn: currentActiveBtn,
      callback: function() {
        showList({
          list: matchingMenu,
          btn: clicked
        })
      }
    })
  } else {
    if (matchingMenu.classList.contains('filters-menu-is-active')) {
      hideList({
        list: matchingMenu,
        btn: clicked
      })
    } else {
      showList({
        list: matchingMenu,
        btn: clicked
      })
    }
  }
}

function hideList(params) {
  const { btn, list, callback } = params
  btn.classList.remove('filters-menu-is-active')
  list.classList.remove('filters-menu-is-active')
  gsap.to(list, {
    duration: 0.2,
    height: 0,
    ease: "none",
    onComplete: function() {
      if (callback) {
        callback()
      }
    }
  })
}

function showList(params) {
  const { btn, list } = params
  btn.classList.add('filters-menu-is-active')
  list.classList.add('filters-menu-is-active')
  gsap.set(list, {height: 'auto'})
  gsap.from(list, {
    duration: 0.4,
    height: 0,
    ease: "power1.out",
  })
}

function resetFilters(e) {
  const btn = e.currentTarget
  const icon = btn.querySelector('[class*=icon]')
  gsap.from(icon, {
    rotation: 360,
    transformOrigin: '50% 60%'
  })

  const form = document.querySelector('#filters-form')
  const filters = Array.from(document.querySelectorAll('.js-filter-input'))
  form.reset()
  filters.forEach((item) => {
    item.checked = false
  })

  updateFilters()
}

function updateFilters(e) {
  if (e) {
    const clicked = e.currentTarget
    const matching = Array.from(document.querySelectorAll(`[id*=${clicked.id}]`)).filter(item => item.id !== clicked.id)[0]
    matching.checked = clicked.checked
  }

  const activeFilters = Array.from(document.querySelectorAll('.js-filter-input:checked'))
  const urlBase = window.location.href.split('?')[0]
  let urlString = ''

  if (activeFilters.length) {
    let filterObjects = activeFilters.map((item) => {
      let obj = {}
      obj.group = item.dataset.group
      obj.value = item.value
      return obj
    })

    // Filter duplicates
    filterObjects = filterObjects.filter((filter, index, self) =>
      index === self.findIndex((item) => (
        item.group === filter.group && item.value === filter.value
      ))
    )

    let grouped = filterObjects.reduce((r, a) => {
     r[a.group] = [...r[a.group] || [], a];
     return r;
    }, {});

    Object.keys(grouped).forEach((key, keyIndex, keyArray) => {
      const array = grouped[key]

      const arrayString = array.reduce((acc, current, index, array) => {
        if (index !== array.length -1) {
          return acc.concat(`${current.value},`)
        } else {
          return acc.concat(current.value)
        }
      }, `${key}=`)

      if (keyIndex !== keyArray.length -1) {
        urlString = urlString.concat(`${arrayString}&`)
      } else {
        urlString = urlString.concat(arrayString)
      }
    })

    urlString = urlBase + '?' + urlString
  } else {
    urlString = urlBase
  }

  ajaxLoad({
    url: urlString,
    mode: 'replace',
  })
}

function initFilters() {
  const filterItems = document.querySelectorAll('.js-filter-input')
  if (filterItems) {
    filterItems.forEach((filter) => {
      filter.addEventListener('change', function(e) {
        updateFilters(e)
      })
    })
  }
}

const filters = {
  init() {
    const form = document.querySelector('#filters-form')

    if (form) {
      const filtersMenuBtns = Array.from(document.querySelectorAll('.js-filters-select-btn'))
      const resetBtn = document.querySelector('#reset-filters')

      filtersMenuBtns.forEach(btn => {
        btn.addEventListener('click', menuBtnHandler)
      })

      resetBtn.addEventListener('click', resetFilters)

      initFilters()
      initResizeListener()
    }
  }
}

export default filters
