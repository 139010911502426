const itemDelay = 200

function handler(entries, observer) {
	let itemLoad = 0

	entries.forEach((entry, entryIndex) => {
    if (entry.intersectionRatio > 0) {
			window.setTimeout(() => {
				entry.target.classList.add('observe-is-visible')
      }, itemLoad*itemDelay)
			itemLoad++;
    	observer.unobserve(entry.target)
    }
  })
}

const content = {
	handler: handler,
  init() {
    const intersectionObserver = new IntersectionObserver(handler, {threshold: 0.1});
		const els = Array.from(document.querySelectorAll('.js-observe'))
		const images = Array.from(document.querySelectorAll('.js-observe-image'))
		const lines = Array.from(document.querySelectorAll('.js-observe-underline'))
		const textBlocks = Array.from(document.querySelectorAll('.js-observe-text'))

		const textEls = textBlocks.map(block => {
			const blockEls = Array.from(block.children)

			return blockEls.map(el => {
				let listEls = []
				listEls = Array.from(el.querySelectorAll('li'))

				if (listEls.length > 0) {
					return listEls
				} else {
					return el
				}
			}).flat()
		}).flat()

		let allEls = [els, images, lines, textEls].flat()

		allEls.sort(function(a,b) {
	    if( a === b) return 0;
	    if( !a.compareDocumentPosition) {
	        // support for IE8 and below
	        return a.sourceIndex - b.sourceIndex;
	    }
	    if( a.compareDocumentPosition(b) & 2) {
	        // b comes before a
	        return 1;
	    }
	    return -1;
		});

		window.addEventListener('load', () => {
			if (allEls.length) {
	      // start observing your elements
	      allEls.forEach(element => intersectionObserver.observe(element))
				window.dispatchEvent(new Event('scroll'))
	    }
		})
  }
}

export default content
