const rootEl = document.documentElement;
const searchBtn = document.querySelector('#search-btn')
const closeBtn = document.querySelector('#search-close')
const input = document.querySelector('#search-input')

function showSearch() {
  rootEl.classList.add('search-is-active')
  input.focus()
}

function hideSearch() {
  rootEl.classList.remove('search-is-active')
}

const search = {
  init() {
    searchBtn.addEventListener('click', showSearch)
    closeBtn.addEventListener('click', hideSearch)
  }
}

export default search
