import axios from 'axios'
import content from './content'

const latestEl = document.querySelector('#latest')
const container = document.querySelector('#latest-items')

let handler = content.handler
let observer
let source

function loadMore(clicked) {
  // Ignore subsequent clicks during an AJAX load:
  if (latestEl.classList.contains('latest-is-loading')) {
    return false
  }

  latest.ajaxLoad({
    url:  clicked.href,
    mode: 'append',
    addToHistory: false,
  })
}

function initObserver() {
  observer = new IntersectionObserver(handler, {threshold: 0.5});
}

function addItems(items) {
  items.forEach(item => {
    container.append(item)
    const addedItem = container.querySelector(`#${item.id}`)
    observer.observe(addedItem)
  })

  latestEl.classList.remove('latest-is-loading', 'latest-is-appending')
}

function updateHistory(options) {
  // Add this AJAX load to the browser's history:
  if (typeof history.pushState !== 'undefined') {
    history.pushState(null, null, options.url)
    updateStorage()
  }
}

function updateStorage() {
  const html = container.innerHTML
  sessionStorage.setItem(window.location.href, html)
}

function handlePopState(e){
  const html = sessionStorage[window.location.href]
  if (html) {
    container.innerHTML = html
    const addedEls = Array.from(container.children)
    addedEls.forEach(item => observer.observe(item))
  }
}

// Allow the back button to work nicely with AJAX-loaded content:
function initPopState() {
  window.addEventListener('popstate', handlePopState)
}

const latest = {
  ajaxLoad(options) {
    // Temporarily disable the "load more" button:
    if (options.mode === 'append') latestEl.classList.add('latest-is-appending')
    latestEl.classList.add('latest-is-loading')

    // If user clicks too quickly on filters cancel and restart ajax calls
    if (source) source.cancel('Operation cancelled by the user.')
    const CancelToken = axios.CancelToken
    source = CancelToken.source()

    // updateHistory defaults to true:
    const addToHistory = (typeof options.addToHistory === 'undefined') ? true : options.addToHistory

    axios({
      url: options.url,
      cancelToken: source.token
    })
    .then(function(res) {
      const resDoc = document.createRange().createContextualFragment(res.data)
      const newContainer = resDoc.querySelector('#latest-items')
      const newItems = Array.from(newContainer.children)
      const oldLoadMore = document.querySelector('#load-more')

      // If mode is replace empty container first
      if (options.mode === 'replace') container.innerHTML = ''

      // Preload all images
      const images = newItems.reduce(function(acc, item) {
        const image = item.querySelector('img')
        if (image) {
          acc.push(image)
        }
        return acc
      }, [])

      if (images.length) {
        let imagesLoaded = 0

        images.forEach((image, i) => {
          const newImage = new Image()
          newImage.onload = () => {
            if (imagesLoaded < images.length -1) {
              imagesLoaded++
            } else {
              // Then update items
              // Remove old load-more button
              if (oldLoadMore) {
                oldLoadMore.remove()
              }

              // Only observe + fade in newly added elements
              addItems(newItems)
              if (addToHistory) updateHistory(options)
            }
          }

          if (image.srcset.length) {
            newImage.srcset = image.srcset
          } else if (image.src.length) {
            newImage.src = image.src
          }
        })
      } else {
        // Only observe + fade in newly added elements
        addItems(newItems)
        if (addToHistory) updateHistory(options)
      }
    })
    .catch(function(thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message)
      } else {
        console.log(thrown)
      }
    })
  },

  init() {
    if (latestEl) {
      latestEl.addEventListener('click', function(e) {
        const clicked = e.target

        if (clicked.id === 'load-more-btn') {
          e.preventDefault()
          loadMore(clicked)
        }
      })

      updateStorage()
      initObserver()
      initPopState()
    }
  }
}

export default latest
