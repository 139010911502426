import gsap from 'gsap'

const header = {
  init() {
    const headerEl = document.querySelector('#header')
    const animEls = Array.from(headerEl.querySelectorAll('.js-header-anim'))
    gsap.from(animEls, {
      duration: 0.5,
      stagger: 0.1,
      opacity: 0,
      y: 10,
      onComplete: function() {
        animEls.forEach(el => gsap.set(el, {clearProps: 'all'}))
      }
    }, "-=0.5")
  }
}

export default header
