import gsap from 'gsap'
let lastWindowWidth = window.innerWidth

function initResizeListener() {
  // Setup a timer
  let timeout
  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout)
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
      if (window.innerWidth !== lastWindowWidth) {
        resizeHandler()
        lastWindowWidth = window.innerWidth
      }
  	})
  }, false)
}

function resizeHandler() {
  const navEl = document.getElementById('nav')
  const currentOpen = navEl.querySelector('.js-nav-list.nav-list-is-open')
  const currentOpenBtn = navEl.querySelector('.js-nav-list-toggle.nav-list-is-open')

  if (currentOpen) {
    showList({
      list: currentOpen,
      btn: currentOpenBtn,
    })
  }
}

function toggleNav() {
  const body = document.body
  body.classList.toggle('nav-is-open')
}

function showList(params) {
  const { btn, list } = params
  btn.classList.add('nav-list-is-open')
  list.classList.add('nav-list-is-open')
  gsap.set(list, {height: 'auto'})
  gsap.from(list, {
    duration: 0.4,
    height: 0,
    ease: "power1.out",
  })
}

function hideList(params) {
  const { btn, list, callback } = params
  btn.classList.remove('nav-list-is-open')
  list.classList.remove('nav-list-is-open')
  gsap.to(list, {
    duration: 0.2,
    height: 0,
    ease: "none",
    onComplete: function() {
      if (callback) {
        callback()
      }
    }
  })
}

function toggleList(e) {
  const navEl = document.getElementById('nav')
  const clicked = e.currentTarget
  const selector = `#nav-list-${clicked.dataset.navList}`
  const match = navEl.querySelector(selector)
  const currentOpen = navEl.querySelector('.js-nav-list.nav-list-is-open')
  const currentOpenBtn = navEl.querySelector('.js-nav-list-toggle.nav-list-is-open')

  if (currentOpen && currentOpen !== match) {
    hideList({
      list: currentOpen,
      btn: currentOpenBtn,
      callback: function() {
        showList({
          list: match,
          btn: clicked
        })
      }
    })
  } else {
    if (match.classList.contains('nav-list-is-open')) {
      hideList({
        list: match,
        btn: clicked
      })
    } else {
      showList({
        list: match,
        btn: clicked
      })
    }
  }
}

const nav = {
  init() {
    const navEl = document.getElementById('nav')
    const navToggleBtn = document.getElementById('nav-toggle')
    const listBtns = Array.from(navEl.querySelectorAll('.js-nav-list-toggle'))

    navToggleBtn.addEventListener('click', toggleNav)
    listBtns.forEach(btn => {
      btn.addEventListener('click', toggleList)
    })

    initResizeListener()
  }
}

export default nav
