require('intersection-observer');

// import cookies from './modules/cookies';
import header from './modules/header';
import nav from './modules/nav';
import content from './modules/content';
import route from './modules/route';
import faqs from './modules/faqs';
import filters from './modules/filters';
import latest from './modules/latest';
import team from './modules/team';
import search from './modules/search';
import signup from './modules/signup';

// cookies();
signup();
content.init();
header.init();
nav.init();
route.init();
faqs.init();
filters.init();
latest.init();
team.init();
search.init();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);
